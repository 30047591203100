@import '../shared/styles/site-globals.scss';
@import '../shared/styles/site/full_page';
@import '../shared/styles/site/photo_credit';

$cbfZ: 16px;

@function Rem($size) {
  $remSize: calc($size / $cbfZ);
  @return #{$remSize}rem;
}

html,
body {
  font-size: $cbfZ;
  font-weight: 400;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
input,
button,
a {
  font-family: 'Lato', 'Open Sans', sans-serif;
}

.logo-subtitle {
  font-size: Rem(18px);
}

h1,
.h1 {
  font-weight: 700;
  @include media-breakpoint-down(sm) {
    font-size: Rem(32px);
  }
}

p {
  font-size: Rem(18px);
  line-height: 1.8;
  margin-bottom: Rem(30px);
  max-width: Rem(620px);

  @include media-breakpoint-up(sm) {
    font-size: Rem(21px);
  }
}

.text-shadow {
  text-shadow: 0 2px rgba(#000, 0.2);
}

ul.org-directory-list {
  -webkit-columns: 200px 3;
  -moz-columns: 200px 3;
  columns: 200px 3;
}

ul.org-directory-list > li {
  display: inline-block;
  width: 100%;
}
