.photo-credit {
  font-size: 14px;
  background: rgba(#000, .5);
  border-radius: 8px;
  opacity: 0.5;
  transition: all 200ms ease;

  &:hover {
    opacity: 1;
    transition: all 200ms ease;
  }
}
